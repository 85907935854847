import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

const FilterSidebar = ({
  products,
  selectedBrand,
  setSelectedBrand,
  selectedRamMemory,
  setSelectedRamMemory,
  selectedGeneration,
  setSelectedGeneration,
  selectedCpu,
  setSelectedCpu,
  clearFilters,
}) => {
  // Get unique options from the products data (filter out any falsey values)
  const uniqueBrands = Array.from(
    new Set(products.map((product) => product.Brand).filter(Boolean))
  );
  const uniqueRamOptions = Array.from(
    new Set(products.map((product) => product.ramMemory).filter(Boolean))
  );
  const uniqueGenerationOptions = Array.from(
    new Set(products.map((product) => product.generation).filter(Boolean))
  );
  const uniqueCpuOptions = Array.from(
    new Set(products.map((product) => product.processor).filter(Boolean))
  );

  // Local state for toggling the visibility of filter groups
  const [showBrandOptions, setShowBrandOptions] = useState(true);
  const [showRamMemoryOptions, setShowRamMemoryOptions] = useState(true);
  const [showGenerationOptions, setShowGenerationOptions] = useState(true);
  const [showCpuOptions, setShowCpuOptions] = useState(true);

  // Handler functions for toggling the selected filter values
  const handleBrandChange = (brand) => {
    setSelectedBrand(selectedBrand === brand ? '' : brand);
  };

  const handleRamChange = (ram) => {
    setSelectedRamMemory(selectedRamMemory === ram ? '' : ram);
  };

  const handleGenerationChange = (gen) => {
    setSelectedGeneration(selectedGeneration === gen ? '' : gen);
  };

  const handleCpuChange = (cpu) => {
    setSelectedCpu(selectedCpu === cpu ? '' : cpu);
  };

  return (
    <aside className="lg:col-span-1 bg-white dark:bg-gray-800 p-6 shadow-lg ring-1 ring-gray-200 dark:ring-gray-700 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">Filters</h2>
        <button
          onClick={clearFilters}
          className="py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 transition-colors"
        >
          Clear Filters
        </button>
      </div>

      {/* Brand Filter */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Brand</h3>
          <button
            onClick={() => setShowBrandOptions(!showBrandOptions)}
            className="focus:outline-none"
          >
            {showBrandOptions ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            )}
          </button>
        </div>
        {showBrandOptions && (
          <div className="space-y-2 transition-all duration-300 ease-in-out">
            {uniqueBrands.map((brand) => (
              <label
                key={brand}
                className="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              >
                <input
                  type="checkbox"
                  value={brand}
                  checked={selectedBrand === brand}
                  onChange={() => handleBrandChange(brand)}
                  className="h-4 w-4 text-blue-500 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-200">{brand}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* RAM Filter */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">RAM</h3>
          <button
            onClick={() => setShowRamMemoryOptions(!showRamMemoryOptions)}
            className="focus:outline-none"
          >
            {showRamMemoryOptions ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            )}
          </button>
        </div>
        {showRamMemoryOptions && (
          <div className="space-y-2 transition-all duration-300 ease-in-out">
            {uniqueRamOptions.map((ram) => (
              <label
                key={ram}
                className="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              >
                <input
                  type="checkbox"
                  value={ram}
                  checked={selectedRamMemory === ram}
                  onChange={() => handleRamChange(ram)}
                  className="h-4 w-4 text-blue-500 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-200">{ram}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Generation Filter */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Generation</h3>
          <button
            onClick={() => setShowGenerationOptions(!showGenerationOptions)}
            className="focus:outline-none"
          >
            {showGenerationOptions ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            )}
          </button>
        </div>
        {showGenerationOptions && (
          <div className="space-y-2 transition-all duration-300 ease-in-out">
            {uniqueGenerationOptions.map((gen) => (
              <label
                key={gen}
                className="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              >
                <input
                  type="checkbox"
                  value={gen}
                  checked={selectedGeneration === gen}
                  onChange={() => handleGenerationChange(gen)}
                  className="h-4 w-4 text-blue-500 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-200">{gen}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Processor Filter */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">Processor</h3>
          <button
            onClick={() => setShowCpuOptions(!showCpuOptions)}
            className="focus:outline-none"
          >
            {showCpuOptions ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            )}
          </button>
        </div>
        {showCpuOptions && (
          <div className="space-y-2 transition-all duration-300 ease-in-out">
            {uniqueCpuOptions.map((cpu) => (
              <label
                key={cpu}
                className="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              >
                <input
                  type="checkbox"
                  value={cpu}
                  checked={selectedCpu === cpu}
                  onChange={() => handleCpuChange(cpu)}
                  className="h-4 w-4 text-blue-500 border-gray-300 dark:border-gray-600 rounded focus:ring-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-200">{cpu}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </aside>
  );
};

export default FilterSidebar;
