const TermsAndConditions = () => {
  const bg =
    "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";

  return (
    <section
      className="py-10 px-4 min-h-screen bg-gray-50 dark:bg-gray-900"
      // style={{
      //   backgroundImage: `url(${bg})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      // }}
    >
      <div className="bg-white dark:bg-gray-800 shadow-lg transition-all rounded-xl max-w-5xl mx-auto">
        <div className="container mx-auto px-10 py-10">
          <h1 className="text-4xl text-orange-500 font-bold mb-8">
            Terms and Conditions
          </h1>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Welcome to Abid Computer. These terms and conditions outline the rules and regulations for the use of Abid Computer’s website.
          </p>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              Acceptance of Terms
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Abid Computer&apos;s website if you do not accept all of the terms and conditions stated on this page.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              License
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Unless otherwise stated, Abid Computer and/or its licensors own the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may view and/or print pages from https://abidcomputer.com for your own personal use, subject to restrictions set in these terms and conditions.
            </p>
            <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
              <li>You must not republish material from this website.</li>
              <li>You must not sell, rent, or sub-license material from this website.</li>
              <li>You must not reproduce, duplicate, or copy material from this website.</li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              User Comments
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Certain parts of this website offer users the opportunity to post comments or reviews. Abid Computer does not filter, edit, publish, or review comments prior to their presence on the website. Comments do not reflect the views or opinions of Abid Computer, its agents, or affiliates. Comments reflect the view of the person who posts such comments.
            </p>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Abid Computer reserves the right to monitor all comments and to remove any comments that it considers inappropriate, offensive, or in breach of these Terms and Conditions.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              Limitation of Liability
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              In no event shall Abid Computer, nor its directors, employees, or agents, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract or otherwise. Abid Computer, including its directors, employees, and agents, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              Termination
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason, including if you breach the Terms.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              Changes to Terms
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              Abid Computer reserves the right to revise these terms and conditions at any time as it sees fit. By using this website, you are expected to review these terms on a regular basis to ensure you understand all terms and conditions governing the use of this website.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800 dark:text-gray-100">
              Contact Us
            </h2>
            <p className="mb-2 text-gray-700 dark:text-gray-300">
              If you have any questions or concerns regarding these Terms and Conditions, please contact us at support@abidcomputer.com.
            </p>
          </section>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
