import { useLocation } from "react-router-dom";

const OrderSuccess = () => {
  const location = useLocation();
  const { trackingNumber, totalPrice } = location.state || {};

  return (
    <section className="py-10 px-4 min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-3xl font-bold mb-5 text-gray-800 dark:text-gray-100">
          Order Successful!
        </h1>
        <p className="text-lg text-gray-700 dark:text-gray-300">
          Thank you for your order. Your order has been placed successfully.
        </p>
        <div className="mt-5">
          <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">
            Order Details:
          </h2>
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Tracking Number:</strong> {trackingNumber}
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Total Price:</strong> Rs. {totalPrice.toLocaleString()}
          </p>
        </div>
        <div className="mt-10">
          <a
            href="/"
            className="text-orange-500 dark:text-orange-400 underline"
          >
            Continue Shopping
          </a>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccess;
