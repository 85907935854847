import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PropTypes from "prop-types";
import { navLinks } from "../constants";
import { ChevronDownIcon } from '@heroicons/react/solid';

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/ACOSLOGO_GLOSSY.png?alt=media&token=a2089b70-0892-462b-b3e3-05c707176c25";
  const hamburger =
    "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/hamburger.svg?alt=media&token=09048c41-2c93-4dbc-bbe6-4783f6565489";

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/LoginPage");
  };

  const handleLinkClick = () => {
    setDropdownOpen(false); // Close dropdown when link is clicked
  };

  return (
    <header className="sticky top-0 left-0 w-full z-50 border-b dark:border-gray-700 lg:bg-n-8/90 dark:lg:bg-gray-800 lg:backdrop-blur-md px-4 py-8">
      <nav className="flex justify-between items-center">
        <a href="/">
          <img src={logo} alt="Logo" width={90} height={90} />
        </a>

        {/* Desktop Nav Links */}
        <ul className="flex-1 hidden lg:flex justify-center items-center gap-16">
          {navLinks.map((item) => (
            <li key={item.label}>
              <Link
                to={item.to}
                className="font-montserrat text-lg font-semibold leading-6 text-orange-600 hover:text-gray-900 dark:hover:text-white"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>

        {/* Desktop Account Dropdown */}
        <div className="relative hidden lg:block">
          <button
            onClick={toggleDropdown}
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-orange-500 hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            My Account
            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-orange-500" />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg py-2">
              {isLoggedIn ? (
                <>
                  <Link
                    to="/MyAccountPage"
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={handleLinkClick}
                  >
                    My Account
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/LoginPage"
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={handleLinkClick}
                  >
                    Login
                  </Link>
                  <Link
                    to="/SignUpPage"
                    className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={handleLinkClick}
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          )}
        </div>

        {/* Mobile Hamburger */}
        <div className="lg:hidden">
          <button onClick={toggleMobileMenu}>
            <img src={hamburger} width={30} height={30} alt="menu" />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="lg:hidden bg-white dark:bg-gray-800 min-h-screen shadow-lg backdrop-blur-md mt-2 w-full">
          <ul className="flex flex-col space-y-8 items-center gap-4 py-4">
            {navLinks.map((item) => (
              <li key={item.label}>
                <Link
                  to={item.to}
                  onClick={() => setMobileMenuOpen(false)}
                  className="font-montserrat text-lg font-semibold text-orange-600 dark:text-orange-400 hover:text-gray-900 dark:hover:text-white"
                >
                  {item.label}
                </Link>
              </li>
            ))}
            <div className="border-t border-gray-300 dark:border-gray-600 w-60 my-2"></div>
            {isLoggedIn ? (
              <>
                <Link
                  to="/MyAccountPage"
                  className="block text-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-4 py-2"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  My Account
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/LoginPage"
                  className="block text-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-4 py-2"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Log In
                </Link>
                <Link
                  to="/SignUpPage"
                  className="block text-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 px-4 py-2"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Sign Up
                </Link>
              </>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default Header;
