import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MediaLibrary from '../components/MediaLibrary';

function EditProduct({ setActiveTab }) {
  // If productId is not available via props, you might fallback to useParams:
  const { productId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    ACIN: '',
    DealCode: '',
    PartNumber: '',
    SerialNumber: '',
    ProductName: '',
    slug: '',
    Category: '',
    Brand: '',
    processor: '',
    generation: '',
    ramMemory: '',
    storage: '',
    screenSize: '',
    displayType: '',
    graphicsCard: '',
    color: '',
    model: '',
    bodyDesign: '',
    stockQuantity: 0,
    Condition: '',
    price: '',
    UnitPrice: '',
    OutletCode: '',
    Status: '',
    imageUrl: null,
    imageArray: [],
    videoUrl: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageType, setImageType] = useState('');

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const fetchProduct = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${productId}`);
      const data = await response.json();
      console.log('Fetched product data:', data);
      // Parse imageArray if it exists, otherwise set to empty array.
      if (data.imageArray) {
        try {
          data.imageArray = JSON.parse(data.imageArray);
        } catch (e) {
          console.error('Error parsing imageArray:', e);
          data.imageArray = [];
        }
      } else {
        data.imageArray = [];
      }
      setFormData(data);
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/update/${productId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Optionally, switch back to the manage products tab after updating.
        navigate("/Dashboard");
      } else {
        console.error('Failed to update product:', await response.text());
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageSelect = (selected) => {
    if (imageType === 'imageUrl') {
      setFormData({ ...formData, imageUrl: selected });
    } else if (imageType === 'imageArray') {
      const newImages = Array.isArray(selected) ? selected : [selected];
      setFormData((prevData) => ({
        ...prevData,
        imageArray: [...prevData.imageArray, ...newImages],
      }));
    }
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <form 
        onSubmit={handleSubmit} 
        className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-6xl mx-auto mb-8 shadow"
      >
        <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Edit Product</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {field}
              </label>
              {field === 'imageUrl' ? (
                <>
                  <div className="flex items-center space-x-4">
                    <input 
                      type="text" 
                      name="imageUrl" 
                      value={formData.imageUrl || ''} 
                      readOnly 
                      className="w-full mt-1 p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                    />
                    <button 
                      type="button" 
                      onClick={() => { setImageType('imageUrl'); setIsModalOpen(true); }} 
                      className="py-2 px-4 bg-blue-500 dark:bg-blue-600 text-white rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700"
                    >
                      Select Image
                    </button>
                  </div>
                  {formData.imageUrl && (
                    <img 
                      src={formData.imageUrl} 
                      alt="Selected" 
                      className="mt-2 h-20 rounded-lg shadow" 
                    />
                  )}
                </>
              ) : field === 'imageArray' ? (
                <>
                  <div className="flex items-center space-x-4">
                    <button 
                      type="button" 
                      onClick={() => { setImageType('imageArray'); setIsModalOpen(true); }} 
                      className="py-2 px-4 bg-green-500 dark:bg-green-600 text-white rounded-lg hover:bg-green-700 dark:hover:bg-green-800"
                    >
                      Select Multiple Images
                    </button>
                  </div>
                  <div className="mt-2 flex space-x-2">
                    {formData.imageArray.map((img, index) => (
                      <img 
                        key={index} 
                        src={img} 
                        alt={`Preview ${index}`} 
                        className="h-20 rounded-lg shadow" 
                      />
                    ))}
                  </div>
                </>
              ) : (
                <input
                  type={field === 'price' || field === 'stockQuantity' ? 'number' : 'text'}
                  name={field}
                  value={formData[field] || ''}
                  onChange={handleChange}
                  className="w-full mt-1 p-2 border border-gray-300 dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
              )}
            </div>
          ))}
          <button 
            type="submit" 
            className="mt-6 w-full py-2 bg-blue-500 dark:bg-blue-600 text-white rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700"
          >
            Update Product
          </button>
        </div>
      </form>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-4xl w-full">
            <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Select Media</h3>
            <MediaLibrary onSelect={handleImageSelect} multiple={imageType === 'imageArray'} />
            <button 
              onClick={() => setIsModalOpen(false)} 
              className="mt-4 bg-red-500 dark:bg-red-600 hover:bg-red-600 dark:hover:bg-red-700 text-white px-4 py-2 rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProduct;