import React, { useState, useEffect } from 'react';
import axios from 'axios';

function MediaLibrary({ onSelect, multiple = false }) {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [file, setFile] = useState(null);
  const itemsPerPage = 24; // Define items per page

  useEffect(() => {
    fetchMediaFiles();
  }, [page]);

  const fetchMediaFiles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/media?page=${page}&limit=${itemsPerPage}`
      );
      if (response.data && Array.isArray(response.data.files)) {
        setMediaFiles(response.data.files);
        setTotalPages(response.data.totalPages);
      } else {
        setMediaFiles([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching media files:', error);
      setMediaFiles([]);
      setLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file) return;
    
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
        timeout: 60000, // Increase timeout to 60 seconds
      });
      setFile(null);
      fetchMediaFiles(); // Refresh media files
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = async (fileUrl) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/media/delete?fileUrl=${encodeURIComponent(fileUrl)}`
      );
      fetchMediaFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleSelect = (file) => {
    setSelectedFiles((prev) => {
      let updatedSelection;
      if (multiple) {
        updatedSelection = prev.includes(file)
          ? prev.filter((f) => f !== file)
          : [...prev, file];
      } else {
        updatedSelection = [file]; // Ensure only one file is selected
      }
  
      // Call onSelect with the updated state
      if (onSelect) {
        onSelect(multiple ? updatedSelection : file);
      }
  
      return updatedSelection;
    });
  };

  const copyToClipboard = (fileUrl) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(fileUrl)
        .then(() => {
          alert('Image link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy with Clipboard API!', err);
        });
    } else {
      // Fallback method using document.execCommand('copy')
      const textArea = document.createElement('textarea');
      textArea.value = fileUrl;
      textArea.style.position = 'fixed';
      textArea.style.top = '-9999px';
      textArea.style.left = '-9999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert('Image link copied to clipboard!');
        } else {
          console.error('Fallback: Copy command was unsuccessful.');
        }
      } catch (err) {
        console.error('Fallback: Unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
  };

  if (loading) return <p className="text-gray-800 dark:text-gray-100">Loading media...</p>;

  return (
    <div className="p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Media Library</h3>

      {/* File Upload */}
      <form onSubmit={handleFileUpload} className="mb-4">
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="mb-2"
        />
        <button type="submit" className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-lg">
          Upload
        </button>
      </form>

      {/* Media Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
        {mediaFiles.length > 0 ? (
          mediaFiles.map((file, index) => (
            <div key={index} className="relative group">
              <img
                src={file}
                alt="media"
                className={`w-full h-full object-contain rounded-lg shadow-md cursor-pointer 
                  transition-transform transform group-hover:scale-105 
                  ${selectedFiles.includes(file) ? 'border-4 border-blue-500' : ''}`}
                onClick={() => handleSelect(file)}
              />
              
              {/* Delete Button */}
              <button
                className="absolute top-2 right-2 bg-red-500 dark:bg-red-600 text-white p-1 rounded-full opacity-75 hover:opacity-100"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(file);
                }}
              >
                ✕
              </button>

              {/* Copy Link Button */}
              {selectedFiles.includes(file) && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(file);
                  }}
                  className="absolute bottom-2 left-2 bg-green-500 dark:bg-green-600 text-white px-2 py-1 rounded-lg opacity-90 hover:opacity-100"
                >
                  Copy Link
                </button>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-800 dark:text-gray-100">No media files available.</p>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-between mt-4">
        <button
          className="bg-gray-300 dark:bg-gray-600 px-4 py-2 rounded-lg disabled:opacity-50"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
        >
          Previous
        </button>
        <span className="text-gray-800 dark:text-gray-100">Page {page} of {totalPages}</span>
        <button
          className="bg-gray-300 dark:bg-gray-600 px-4 py-2 rounded-lg disabled:opacity-50"
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default MediaLibrary;