import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ManageProducts() {
  const [products, setProducts] = useState([]); // Full list from the API
  const [displayedProducts, setDisplayedProducts] = useState([]); // Products to display
  const [searchTerm, setSearchTerm] = useState(''); // Search input value

  useEffect(() => {
    fetchProducts();
  }, []);

  // When products load, display them all by default.
  useEffect(() => {
    setDisplayedProducts(products);
  }, [products]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`);
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${productId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          fetchProducts(); // Refresh the product list
        } else {
          console.error('Failed to delete product:', await response.text());
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  // Triggered only when the Search button is clicked
  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      // If search term is empty, show all products.
      setDisplayedProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.ProductName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDisplayedProducts(filtered);
    }
  };

  // Optional: Reset the search and display all products again.
  const handleReset = () => {
    setSearchTerm('');
    setDisplayedProducts(products);
  };

  return (
    <div className="min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Manage Products</h2>

      {/* Search Input and Buttons */}
      <div className="mb-4 flex space-x-2">
        <input
          type="text"
          placeholder="Search by product name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 dark:border-gray-600 rounded flex-grow bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
        />
        <button
          onClick={handleSearch}
          className="bg-green-500 hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
        >
          Search
        </button>
        <button
          onClick={handleReset}
          className="bg-gray-500 hover:bg-gray-700 dark:bg-gray-600 dark:hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
        >
          Reset
        </button>
      </div>

      <table className="w-full border border-gray-200 dark:border-gray-700">
        <thead>
          <tr>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Part Number</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Product Name</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Category</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Brand</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Price</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">OutletCode</th>
            <th className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedProducts.map((product) => (
            <tr key={product.ProductID}>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">{product.PartNumber}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">{product.ProductName}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">{product.Category}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">{product.Brand}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">${product.price}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2 text-gray-800 dark:text-gray-100">{product.OutletCode}</td>
              <td className="border border-gray-200 dark:border-gray-700 p-2">
                <Link to={`/edit-product/${product.ProductID}`}>
                  <button className="bg-blue-500 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mr-2">
                    Edit
                  </button>
                </Link>
                <button
                  className="bg-red-500 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleDelete(product.ProductID)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {displayedProducts.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center p-4 text-gray-500 dark:text-gray-400">
                No products found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ManageProducts;
