import React from 'react'

const BankDetails = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
        <div className="p-6 bg-gray-100 rounded-md shadow-lg">
            <h1 className="text-2xl font-bold mb-4">Bank Account Details</h1>
            <p>Account Name: XYZ</p>
            <p>Account Number: 123456789</p>
            <p>Bank Name: ABC Bank</p>
            <p className="mt-4 text-gray-500">
                Please make your payment and use the order ID as the reference.
            </p>
        </div>
    </div>
  )
}

export default BankDetails
