import { useState } from "react";
import ManageProducts from "./ManageProducts";
import AddProduct from "./AddProduct";
import SalesReports from "./SalesReports";
import FileUpload from "../components/FileUpload";
import MediaLibrary from "../components/MediaLibrary";
import EditProduct from "../components/EditProduct";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("manage-products");
  const [selectedProductId, setSelectedProductId] = useState(null);

  const renderTabContent = () => {
    switch (activeTab) {
      case "manage-products":
        return (
          <ManageProducts
            setActiveTab={setActiveTab}
            setSelectedProductId={setSelectedProductId}
          />
        );
      case "edit-product":
        return <EditProduct productId={selectedProductId} />;
      case "add-product":
        return <AddProduct />;
      case "sales-reports":
        return <SalesReports />;
      case "media-library":
        return <MediaLibrary />;
      default:
        return (
          <ManageProducts
            setActiveTab={setActiveTab}
            setSelectedProductId={setSelectedProductId}
          />
        );
    }
  };

  return (
    <div className="min-h-screen flex bg-gray-100 dark:bg-gray-900">
      {/* Sidebar */}
      <div className="w-64 bg-white dark:bg-gray-800 shadow-lg">
        <div className="h-16 flex items-center justify-center font-bold text-xl text-orange-600 dark:text-orange-400">
          Dashboard
        </div>
        <ul className="space-y-2 mt-8">
          <li>
            <button
              onClick={() => setActiveTab("manage-products")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "manage-products"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              }`}
            >
              Manage Products
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("add-product")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "add-product"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              }`}
            >
              Add Product
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("sales-reports")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "sales-reports"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              }`}
            >
              Sales Reports
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("media-library")}
              className={`w-full text-left px-6 py-3 font-semibold ${
                activeTab === "media-library"
                  ? "bg-orange-600 text-white"
                  : "text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              }`}
            >
              Media Library
            </button>
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
