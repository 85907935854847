import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useComparison } from '../context/CompareContext';
import ProductComparison from '../components/Comparison';
import ShopByCategory from '../components/ShopByCategory';
import FilterSidebar from '../components/FilterSidebar';
import ProductListing from '../components/ProductListing';
import BackToTopButton from '../components/BackToTopButton';

const HomePage = ({ currentProduct, onAddToCompare, product, productName }) => {
  const { comparisonList, addToComparison } = useComparison();
  const [isComparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedRamMemory, setSelectedRamMemory] = useState('');
  const [selectedGeneration, setSelectedGeneration] = useState('');
  const [selectedCpu, setSelectedCpu] = useState('');
  const [wishlist, setWishlist] = useState(() => JSON.parse(localStorage.getItem('wishlist')) || []);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Fetch products from the backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/grouped`);
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        console.error('Error fetching products:', err);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextVideo();
    }, 10000); // Switch every 10 seconds
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentVideoIndex]);

  // Reset to the first page when filters or products change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBrand, selectedRamMemory, selectedGeneration, selectedCpu, products]);

  const clearFilters = () => {
    setSelectedBrand('');
    setSelectedRamMemory('');
    setSelectedGeneration('');
    setSelectedCpu('');
  };

  // Apply filters based on the selected options
  const filteredProducts = products.filter((product) => {
    const matchesBrand = selectedBrand ? product.Brand === selectedBrand : true;
    const matchesRamMemory = selectedRamMemory ? product.ramMemory === selectedRamMemory : true;
    const matchesGeneration = selectedGeneration ? product.generation === selectedGeneration : true;
    const matchesCpu = selectedCpu ? product.processor === selectedCpu : true;
    return matchesBrand && matchesRamMemory && matchesGeneration && matchesCpu;
  });

  // Calculate pagination variables
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleAddToWishlist = (product) => {
    setWishlist((prevWishlist) => {
      const newWishlist = [...prevWishlist, product];
      localStorage.setItem('wishlist', JSON.stringify(newWishlist));
      return newWishlist;
    });
  };

  const closeComparisonModal = () => setComparisonModalOpen(false);

  // Background image URL (you can replace this with a dark-themed image if desired)
  // eslint-disable-next-line
  const bg = "https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/BG.png?alt=media&token=19dbc297-4316-45fa-aef5-2a511062841a";
  const videoBanners = [
    "https://videos.pexels.com/video-files/3255275/3255275-uhd_2560_1440_25fps.mp4",
    "https://videos.pexels.com/video-files/6248612/6248612-uhd_2560_1440_25fps.mp4",
    "https://videos.pexels.com/video-files/6248600/6248600-uhd_2560_1440_25fps.mp4",
  ];
  const prevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videoBanners.length - 1 : prevIndex - 1
    );
  };

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videoBanners.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <section
        className="min-h-screen bg-white dark:bg-gray-900"
        // style={{
        //   backgroundImage: `url(${bg})`,
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        // }}
      >
        {/* Video Carousel Section */}
        <div className="relative w-full h-[780px]">
          <video
            key={currentVideoIndex}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src={videoBanners[currentVideoIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black/30 flex flex-col items-center justify-center">
            <h1 className="text-white text-4xl md:text-6xl font-bold text-center">
              Welcome to Abid Computers!
            </h1>
            <p className="text-white text-lg md:text-xl mt-2 text-center">
              Discover the best deals and products
            </p>
          </div>
          <button
            onClick={prevVideo}
            className="text-orange-500 text-3xl hover:text-gray-900 dark:hover:text-gray-100 transition-all absolute top-[50%] left-5 transform -translate-y-1/2 bg-black/50 p-3 rounded-full shadow-lg hover:bg-black/80"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={nextVideo}
            className="text-orange-500 text-3xl transition-all absolute top-[50%] right-5 transform -translate-y-1/2 bg-black/50 p-3 rounded-full shadow-lg hover:bg-black/80"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <ShopByCategory />

        <div className="max-w-8xl gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ml-8 me-8">
          {/* Sidebar with Dynamic Filter Options */}
          <FilterSidebar
            products={products}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            selectedRamMemory={selectedRamMemory}
            setSelectedRamMemory={setSelectedRamMemory}
            selectedGeneration={selectedGeneration}
            setSelectedGeneration={setSelectedGeneration}
            selectedCpu={selectedCpu}
            setSelectedCpu={setSelectedCpu}
            clearFilters={clearFilters}
          />

          {/* Product Listings */}
          <section className="lg:col-span-3">
            <ProductListing 
              products={currentProducts} 
              handleAddToWishlist={handleAddToWishlist} 
            />

            {/* Pagination Controls */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-3 py-1 mx-1 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded disabled:opacity-50"
                >
                  Prev
                </button>

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`px-3 py-1 mx-1 rounded ${
                      currentPage === index + 1
                        ? 'bg-orange-500 text-white'
                        : 'bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}

                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 mx-1 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            )}
          </section>
        </div>
      </section>

      {/* Comparison Modal */}
      <ProductComparison
        isOpen={isComparisonModalOpen}
        onClose={closeComparisonModal}
        comparisonList={comparisonList}
      />

      {/* Back to Top Button */}
      <BackToTopButton />
    </>
  );
};

export default HomePage;
