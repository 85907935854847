// src/pages/ShopPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilterSidebar from '../components/FilterSidebar';
import ProductListing from '../components/ProductListing';
import BackToTopButton from '../components/BackToTopButton';

const ShopPage = () => {
  const { category } = useParams(); // Get the category from the URL

  // Store products after category filtering.
  const [allProducts, setAllProducts] = useState([]);

  // Sidebar filter states.
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedRamMemory, setSelectedRamMemory] = useState('');
  const [selectedGeneration, setSelectedGeneration] = useState('');
  const [selectedCpu, setSelectedCpu] = useState('');

  // Pagination state.
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Adjust the number of items per page as needed.

  useEffect(() => {
    // Fetch products and filter by the selected category.
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/grouped/`);
        const data = await response.json();

        // If the category is 'all' or undefined, show all products.
        const filteredByCategory =
          category && category.toLowerCase() !== 'all'
            ? data.filter((product) => product.Category === category)
            : data;

        setAllProducts(filteredByCategory);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [category]);

  // Clear all filter selections.
  const clearFilters = () => {
    setSelectedBrand('');
    setSelectedRamMemory('');
    setSelectedGeneration('');
    setSelectedCpu('');
  };

  // Apply additional filters based on the selected options.
  const filteredProducts = allProducts.filter((product) => {
    const matchesBrand = selectedBrand ? product.Brand === selectedBrand : true;
    const matchesRamMemory = selectedRamMemory ? product.ramMemory === selectedRamMemory : true;
    const matchesGeneration = selectedGeneration ? product.generation === selectedGeneration : true;
    const matchesCpu = selectedCpu ? product.processor === selectedCpu : true;
    return matchesBrand && matchesRamMemory && matchesGeneration && matchesCpu;
  });

  // Reset pagination to the first page when filters or product data changes.
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBrand, selectedRamMemory, selectedGeneration, selectedCpu, allProducts, category]);

  // Calculate pagination variables.
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // A simple wishlist handler. You can expand this to integrate with your wishlist logic or context.
  const handleAddToWishlist = (product) => {
    console.log('Added to wishlist:', product);
    // Implement your wishlist functionality here.
  };

  return (
    <section className="dark:bg-gray-900 text-gray-800 dark:text-gray-100">
    <div className="shop-page container mx-auto p-4 bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-100">
      <h1 className="text-3xl font-bold mb-6">
        {category && category.toLowerCase() === 'all'
          ? 'All Products'
          : `Products in ${category}`}
      </h1>
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Sidebar with filters */}
        <div className="lg:w-1/4 mb-4 lg:mb-0">
          <FilterSidebar
            products={allProducts}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            selectedRamMemory={selectedRamMemory}
            setSelectedRamMemory={setSelectedRamMemory}
            selectedGeneration={selectedGeneration}
            setSelectedGeneration={setSelectedGeneration}
            selectedCpu={selectedCpu}
            setSelectedCpu={setSelectedCpu}
            clearFilters={clearFilters}
          />
        </div>
        {/* Product Listings */}
        <div className="lg:w-3/4">
          {filteredProducts.length ? (
            <>
              <ProductListing
                products={currentProducts}
                handleAddToWishlist={handleAddToWishlist}
              />

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <div className="flex justify-center items-center mt-4">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 mx-1 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded disabled:opacity-50"
                  >
                    Prev
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      className={`px-3 py-1 mx-1 rounded ${
                        currentPage === index + 1
                          ? 'bg-orange-500 text-white'
                          : 'bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 mx-1 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          ) : (
            <p className="text-center text-gray-500 dark:text-gray-400">
              No products found for this category.
            </p>
          )}
        </div>
      </div>
      {/* Back to Top Button */}
      <BackToTopButton />
    </div>
    </section>
  );
};

export default ShopPage;
