import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import Cart from './pages/Cart'
import Dashboard from './pages/Dashboard'
import ProductDetails from './pages/ProductDetails'
import LoginPage from './pages/LoginPage'
import SignUpPage from './pages/SignUpPage'
import Checkout from './pages/Checkout'
import PaymentPage from './pages/PaymentPage'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import WishlistPage from './pages/WishlistPage'
import { ComparisonProvider } from './context/CompareContext'
import EditProduct from './components/EditProduct'
import OrderSuccess from './pages/OrderSuccess'
import BankDetails from './pages/BankDetails'
import ShopPage from './pages/ShopPage'

function App() {
    return (
        <ComparisonProvider>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/About" element={<AboutPage />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route
                        path="/products/grouped/:slug"
                        element={<ProductDetails />}
                    />
                    <Route
                        path="/edit-product/:productId"
                        element={<EditProduct />}
                    />
                    <Route path="/shop/:category" element={<ShopPage />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/payment" element={<PaymentPage />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route
                        path="/terms&conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route path="/Wishlist" element={<WishlistPage />} />
                    <Route path="/LoginPage" element={<LoginPage />} />
                    <Route path="/SignUpPage" element={<SignUpPage />} />
                    <Route path="/order-success" element={<OrderSuccess />} />
                    <Route path="/bank-details" element={<BankDetails />} />
                </Routes>
                <Footer />
            </Router>
        </ComparisonProvider>
    )
}

export default App
