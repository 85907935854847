// src/components/ProductListing.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useComparison } from '../context/CompareContext';

const ProductListing = ({ products, handleAddToWishlist }) => {
  const navigate = useNavigate();
  const { addToComparison } = useComparison();

  const handleProductClick = (slug) => {
    navigate(`/products/grouped/${slug}`);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {products.length ? (
        products.map((product) => (
          <div
            key={product.slug}
            className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition transform hover:-translate-y-1 hover:scale-105 cursor-pointer"
          >
            <div className="relative">
              <img
                src={
                  product.imageUrl ||
                  'https://firebasestorage.googleapis.com/v0/b/abidco-526ca.appspot.com/o/woocommerce-placeholder.png?alt=media&token=27e9da3d-7159-4a01-a9ed-0a7d77473357'
                }
                alt={product.ProductName}
                className="w-full h-auto object-cover"
                onClick={() => handleProductClick(product.slug)}
              />
              <div className="absolute top-2 right-2 flex space-x-2">
                <button
                  onClick={() =>
                    addToComparison({
                      slug: product.slug,
                      ProductName: product.ProductName,
                      imageUrl: product.imageUrl,
                      price: product.price,
                      processor: product.processor,
                      generation: product.generation,
                      ramMemory: product.ramMemory,
                      storage: product.storage,
                      screenSize: product.screenSize,
                      graphicsCard: product.graphicsCard,
                      displayType: product.displayType,
                    })
                  }
                  className="bg-white dark:bg-gray-800 p-2 rounded-full shadow-md text-green-600 hover:text-green-800 dark:hover:text-green-400"
                  title="Add to Compare"
                >
                  <FontAwesomeIcon icon={faBalanceScale} />
                </button>
                <button
                  onClick={() => handleAddToWishlist(product)}
                  className="bg-white dark:bg-gray-800 p-2 rounded-full shadow-md text-red-500 hover:text-red-700 dark:hover:text-red-400"
                  title="Add to Wishlist"
                >
                  <FontAwesomeIcon icon={faHeart} />
                </button>
              </div>
            </div>
            <div className="p-4">
              <h3 className="text-xl font-bold text-gray-800 dark:text-gray-100">
                {product.ProductName}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-sm mt-1">
                Generation: {product.generation} <br />
                RAM: {product.ramMemory} <br />
                Storage: {product.storage} <br />
                Processor: {product.processor}
              </p>
              <div className="mt-4">
                <button
                  onClick={() => handleProductClick(product.slug)}
                  className="w-full bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 px-4 rounded transition"
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="col-span-full text-center text-gray-500 dark:text-gray-400">
          No products match your filters.
        </p>
      )}
    </div>
  );
};

export default ProductListing;
