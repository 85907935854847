// src/components/ShopByCategory.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ShopByCategory = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the grouped products and extract unique category objects.
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/grouped`);
        const data = await response.json();

        // Group products by category so each category is represented by one product.
        const groupedCategories = data.reduce((acc, product) => {
          if (!acc[product.Category]) {
            // Save the first product of each category as the representative.
            acc[product.Category] = product;
          }
          return acc;
        }, {});

        // Convert the grouped object into an array of category objects.
        const uniqueCategories = Object.values(groupedCategories);
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategorySelect = (categoryName) => {
    // Navigate to the ShopPage for the selected category.
    navigate(`/shop/${encodeURIComponent(categoryName)}`);
  };

  const handleSeeAll = () => {
    // Navigate to the ShopPage to show all products.
    navigate('/shop/all');
  };

  return (
    <section className="py-14 md:py-24 bg-white dark:bg-[#0b1727] text-zinc-900 dark:text-white relative overflow-hidden z-10 mb-4">
      <div className="container px-4 mx-auto">
        <div className="flex items-center w-full">
          <div className="w-2/3">
            <h2 className="text-2xl leading-none md:text-[40px] font-bold">
              Shop By Category
            </h2>
          </div>
          <div className="w-1/3 text-end">
            <button
              onClick={handleSeeAll}
              className="text-lg font-bold leading-normal hover:text-blue-600 transition"
            >
              See All
            </button>
          </div>
        </div>
        <div className="grid grid-cols-12 text-center gap-6 mt-6 md:mt-12">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCategorySelect(category.Category)}
              className="cursor-pointer rounded-xl p-4 border hover:bg-orange-400 transition-all col-span-12 md:col-span-6 lg:col-span-3"
            >
              <div className="rounded-xl bg-white dark:bg-slate-800 border dark:border-none p-12 pb-4">
                <div className="h-52 w-auto rounded-xl flex justify-center items-center">
                  <img
                    src={category.imageUrl || '/default-image.jpg'}
                    alt={category.Category}
                    className="max-h-full max-w-full w-auto"
                  />
                </div>
                <div className="p-4 md:p-6">
                  <h2 className="text-lg font-bold leading-none mt-2">
                    {category.Category}
                  </h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ShopByCategory;