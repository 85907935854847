const SalesReports = () => {
  const sales = [
    { id: 1, productName: "Product 1", quantitySold: 5, revenue: 500 },
    { id: 2, productName: "Product 2", quantitySold: 3, revenue: 300 },
  ];

  return (
    <div className="p-4 bg-gray-50 dark:bg-gray-900 min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">
        Sales Reports
      </h1>
      <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <thead className="bg-gray-100 dark:bg-gray-700">
          <tr>
            <th className="py-2 px-4 text-left text-gray-700 dark:text-gray-300">Product</th>
            <th className="py-2 px-4 text-left text-gray-700 dark:text-gray-300">Quantity Sold</th>
            <th className="py-2 px-4 text-left text-gray-700 dark:text-gray-300">Revenue</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
          {sales.map((sale) => (
            <tr key={sale.id}>
              <td className="py-2 px-4 text-gray-800 dark:text-gray-100">{sale.productName}</td>
              <td className="py-2 px-4 text-gray-800 dark:text-gray-100">{sale.quantitySold}</td>
              <td className="py-2 px-4 text-gray-800 dark:text-gray-100">${sale.revenue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesReports;
